import React, { useEffect } from "react";
import { Accordion } from "react-bootstrap";
import "../styles/PreguntasFrecuentes.css";

const preguntasFrecuentes = [
  {
    pregunta: "¿Qué cursos y certificaciones ofrece Fundación Nican?",
    respuesta:
      "Curso de Auxiliar de enfermería, ciencias Quirofísicas, Masajes, Quiropráctica, certificaciones en EC0616, EC076, EC217.01, EC1375, EC0301, EC0366.",
  },
  {
    pregunta: "¿Qué requisitos necesito para inscribirme en un curso?",
    respuesta:
      "Los documentos que solicitamos para curso son INE, CURP, comprobante de domicilio.",
  },
  {
    pregunta: "¿La certificación tiene validez oficial ante la SEP o CONOCER?",
    respuesta:
      "Así es, la certificación tiene validez ante la SEP CONOCER y puedes consultar tu certificado en la página del CONOCER en la RENAP personas que cuentan con una certificación.",
  },
  {
    pregunta: "¿Qué horarios están disponibles para cada curso?",
    respuesta:
      "Los horarios de curso son de lunes a viernes de 9:00 am a 1:00 pm y sábados de 9:00 am a 6:00 pm.",
  },
  {
    pregunta: "¿Existen opciones de financiamiento o becas?",
    respuesta:
      "Sí, puedes obtener una beca de descuento en cursos. Para ello necesitamos un estudio socioeconómico para determinar el porcentaje y cumplir con algunas especificaciones propias de cada curso.",
  },
  {
    pregunta: "¿Puedo realizar las prácticas en hospitales o centros de salud?",
    respuesta:
      "Las prácticas se realizan en campo mediante campañas para ofrecer atención a población vulnerable.",
  },
  {
    pregunta:
      "¿Cómo puedo obtener más información sobre los programas de capacitación?",
    respuesta:
      "Puedes llamar directamente al teléfono 55-79-11-85-89 y con gusto un asesor te resolverá todas tus preguntas y te dará orientación para ver qué programa te conviene más.",
  },
  {
    pregunta: "¿Dónde está ubicada Fundación Nican y cómo puedo contactarlos?",
    respuesta:
      "Fundación Nican está en Ixtapaluca, Estado de México. Puedes contactarnos por WhatsApp, Facebook e Instagram.",
  },
  {
    pregunta: "¿Fundación Nican ofrece cursos en línea o solo presenciales?",
    respuesta:
      "Algunos programas son en línea como la alineación para certificación en EC0217.01, EC0301, EC0366. Los cursos de capacitación en áreas de salud son presenciales.",
  },
  {
    pregunta:
      "¿Cuáles son los beneficios de obtener una certificación laboral?",
    respuesta:
      "Al contar con una certificación de competencia laboral tienes más oportunidades de tener un mejor ingreso, una mejor calidad de vida y, en algunos estándares, tener un autoempleo que te permitirá manejar tus tiempos y pasar más tiempo en familia.",
  },
  {
    pregunta: "¿Puedo trabajar mientras estudio en Fundación Nican?",
    respuesta:
      "Sí, puedes trabajar mientras estudias ya que puedes adaptar tu horario.",
  },
  {
    pregunta: "¿Los cursos incluyen materiales de estudio?",
    respuesta:
      "Para tomar un curso, tú tienes que adquirir tu propio material, mismo que te servirá para después ofertar tus servicios.",
  },
  {
    pregunta: "¿Qué sucede si deseo cambiar de curso después de inscribirme?",
    respuesta:
      "Al inscribirte a uno de nuestros cursos tendrás la oportunidad de cambiar si está en disponibilidad y se adapta al programa.",
  },
];

function addFaqSchema() {
  const schemaData = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: preguntasFrecuentes.map((faq) => ({
      "@type": "Question",
      name: faq.pregunta,
      acceptedAnswer: {
        "@type": "Answer",
        text: faq.respuesta,
      },
    })),
  };

  const script = document.createElement("script");
  script.type = "application/ld+json";
  script.innerHTML = JSON.stringify(schemaData);
  document.head.appendChild(script);
}

function PreguntasFrecuentes() {
  React.useEffect(() => {
    addFaqSchema();
  }, []);

  useEffect(() => {
    document.title = "Preguntas Frecuentes - Fundación Nican";
    document
      .querySelector('meta[name="description"]')
      ?.setAttribute(
        "content",
        "Encuentra las respuestas a las preguntas más frecuentes sobre cursos, certificaciones, requisitos y horarios en Fundación Nican."
      );
  }, []);

  return (
    <div id="FQA" className=" bg-light  preguntas-frecuentes-container">
      <h2 className="text-center mb-4" data-aos="fade-up">
        Preguntas Frecuentes
      </h2>
      <Accordion className="container my-4">
        {preguntasFrecuentes.map((item, index) => (
          <Accordion.Item
            eventKey={index}
            key={index}
            data-aos="fade-up"
            data-aos-delay={index * 100}
          >
            <Accordion.Header>
              <h3 className="mb-0">{item.pregunta}</h3>
            </Accordion.Header>
            <Accordion.Body>{item.respuesta}</Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </div>
  );
}

export default PreguntasFrecuentes;
