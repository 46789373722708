import React from "react";
import "../styles/Mapa.css";
import { FaFacebook, FaWhatsapp, FaEnvelope, FaGlobe } from "react-icons/fa";

function Conocer() {
  return (
    <div
      className="mapa-container container-fluid conocer my-5"
      data-aos="fade-up"
    >
      <div className="container">
        <div className="row my-5">
          <div className="row d-flex">
            <div className="col-md-6 text-center mb-3">
              <img src="https://framework-gb.cdn.gob.mx/gobmx/img/logo_blanco.svg" />
            </div>
            <div className="col-md-6 my-3">
              <h2 className="text-center"> CONOCER</h2>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-6">
            <img
              src="https://conocer.gob.mx/wp-content/uploads/2017/03/placeholder-mapa.jpg"
              className="img-fluid w-100 h-100 mb-3"
              alt="Mapa-Conocer.gob.mx"
            />
          </div>

          <div className="col-md-6 contact-info">
            <h3>Información de contacto</h3>

            <p>
              <b>El CONOCER</b> coordina y promueve el Sistema Nacional de
              Competencias para que México cuente con empresarios, trabajadores,
              docentes, estudiantes y servidores públicos más competentes.
            </p>
            <p>
              <strong>Dirección:</strong> Barranca del Muerto 275, 1er. piso,
              Colonia San José Insurgentes, Benito Juárez, C.P. 03900, Ciudad de
              México
            </p>
            <p>
              <strong>Horario de atención:</strong> Lunes a Jueves de 9:00am a
              6:30pm <br />
              Viernes de 9:00am a 3:00pm
            </p>
            <div className="contact-links">
              <div className="contact-item">
                <FaWhatsapp className="icon" />
                55 2282 0200
              </div>
              <div className="contact-item">
                <FaEnvelope className="icon" />
                <a
                  href="mailto:contacto@conocer.gob.mx"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  contacto@conocer.gob.mx
                </a>
              </div>
              <div className="contact-item">
                <FaGlobe className="icon" />
                <a
                  href="https://conocer.gob.mx/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://conocer.gob.mx/
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Conocer;
