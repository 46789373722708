import React from "react";
import "../styles/VideoRecomendacion.css";

function VideoRecomendacion() {
  return (
    <section className="video-section bg-light my-5">
      {/* Título */}
      <h2 className="text-center text-primary mb-4">
        Nuestros alumnos nos recomiendan
      </h2>

      {/* Frase motivadora */}
      <p className="text-center  fs-5 mb-5">
        "Transforma tu vida y alcanza tus metas con nosotros. ¡Descubre cómo
        podemos ayudarte a lograrlo!"
      </p>

      {/* Video */}
      <div className="video-container">
        <video
          className="video-content"
          controls
          preload="none"
          poster="https://nican-landing-page.vercel.app/Img/fundacion-nican.png"
        >
          <source
            src="https://nican-landing-page.vercel.app/Video/fundacionnican.mp4"
            type="video/mp4"
          />
          Tu navegador no soporta el elemento de video.
        </video>
      </div>
    </section>
  );
}

export default VideoRecomendacion;
