import React, { useState, useEffect } from "react";
import { FaArrowLeft, FaArrowRight, FaDownload } from "react-icons/fa";
import "../styles/CertificadosCarousel.css";
import certificacionesData from "../data/certificacionesData";

const carouselInterval = 4500;

const CertificadosCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [cardsToShow, setCardsToShow] = useState(2); // Muestra 2 por defecto en pantallas grandes

  useEffect(() => {
    const updateCardsToShow = () => {
      if (window.innerWidth < 768) {
        setCardsToShow(1); // Cambia a 1 card en pantallas pequeñas
      } else {
        setCardsToShow(2); // Cambia a 2 cards en pantallas grandes
      }
    };
    window.addEventListener("resize", updateCardsToShow);
    updateCardsToShow();

    return () => window.removeEventListener("resize", updateCardsToShow);
  }, []);

  const nextSlide = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex + cardsToShow) % certificacionesData.length
    );
  };

  const prevSlide = () => {
    setCurrentIndex(
      (prevIndex) =>
        (prevIndex - cardsToShow + certificacionesData.length) %
        certificacionesData.length
    );
  };

  return (
    <div
      className="container carousel-container"
      data-aos="fade-right"
      id="certificaciones"
    >
      <div className="row text-center my-5">
        <h2>Conoce nuestras Certificaciones y Diplomados</h2>
      </div>
      <div className="row carousel">
        <div className="col-1 p-0 m-0">
          <button className="carousel-button prev" onClick={prevSlide}>
            <FaArrowLeft />
          </button>
        </div>

        <div className="carousel-cards col-10">
          <div className="row justify-content-center">
            {certificacionesData
              .slice(currentIndex, currentIndex + cardsToShow)
              .map((cert, idx) => (
                <div key={idx} className="card mx-3 p-0">
                  <h3
                    className={`card-header ${
                      idx % 2 === 0 ? "header-primary" : "header-secondary"
                    }`}
                  >
                    {cert.nombre}
                  </h3>
                  <img
                    src={
                      cert.imagen
                        ? require(`../imgs/certificados/${cert.imagen}`)
                        : "https://marketplace.canva.com/EAFQNGff-B8/1/0/1600w/canva-certificado-de-reconocimiento-simple-azul-y-amarillo-jVqupEcSv7g.jpg"
                    }
                    alt={cert.nombre}
                    className="w-100"
                    style={{ objectFit: "cover" }}
                  />
                  <div className="card-body overflow-auto">
                    <p>
                      <strong>Beneficios</strong>
                    </p>
                    <p className="text-justify">{cert.beneficios}</p>
                    <h4>Temario:</h4>
                    <ul>
                      {cert.temario.slice(0, 3).map((tema, idx) => (
                        <li key={idx} className="text-justify">
                          <strong>{tema.tema}:</strong> {tema.descripcion}
                        </li>
                      ))}
                    </ul>
                    <a href="#" className="conocer-mas-link">
                      Solicita mas información sin compromiso
                    </a>
                  </div>
                  <div className="card-footer d-flex justify-content-between">
                    <div className="footer-left">{cert.nombre}</div>
                    {cert.descargable && (
                      <div className="footer-right">
                        <FaDownload
                          onClick={() => {
                            const link = document.createElement("a");
                            link.href = require(`../data/Informacion/${cert.descargable}`);
                            link.download = cert.descargable;
                            link.click();
                          }}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              ))}
          </div>
        </div>

        <div className="col-1 p-0 m-0 Card-Button-P">
          <button className="carousel-button next" onClick={nextSlide}>
            <FaArrowRight />
          </button>
        </div>
      </div>
    </div>
  );
};

export default CertificadosCarousel;
