import React, { useEffect, useState } from "react";
import { FaStar } from "react-icons/fa";
import "../styles/CalificacionesRocola.css";

const calificaciones = [
  {
    calificacion: 5,
    nombre: "Ana López",
    comentario: "Excelente curso, me ayudó muchísimo.",
  },
  {
    calificacion: 4,
    nombre: "Juan Pérez",
    comentario: "Muy bueno, aunque podría mejorar algunos detalles.",
  },
  {
    calificacion: 5,
    nombre: "Carlos Gómez",
    comentario: "¡Impresionante! Superó todas mis expectativas.",
  },
  {
    calificacion: 4,
    nombre: "Luisa Fernández",
    comentario: "Un curso muy completo y bien estructurado.",
  },
  {
    calificacion: 5,
    nombre: "María Castillo",
    comentario:
      "El curso fue increíble, me abrió muchas oportunidades laborales.",
  },
  {
    calificacion: 4,
    nombre: "Pedro Hernández",
    comentario:
      "Muy completo y bien explicado, aunque algunas secciones podrían ser más dinámicas.",
  },
  {
    calificacion: 5,
    nombre: "Andrea Ramírez",
    comentario: "¡Me encantó! Todo estuvo claro y muy práctico.",
  },
  {
    calificacion: 4,
    nombre: "Ricardo Torres",
    comentario: "Gran experiencia, lo recomendaría a todos mis colegas.",
  },
];

function CalificacionesRocola() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isHovering, setIsHovering] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isHovering && window.innerWidth >= 768) {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % calificaciones.length);
      }
    }, 6000); // Cambia cada 6 segundos

    return () => clearInterval(interval);
  }, [isHovering]);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % calificaciones.length);
  };

  const prevSlide = () => {
    setCurrentIndex(
      (prevIndex) =>
        (prevIndex - 1 + calificaciones.length) % calificaciones.length
    );
  };

  return (
    <div className="container my-5" id="calificaciones">
      <h1 className="text-center mb-5">
        Conoce las opiniones de nuestros estudiantes
      </h1>

      {/* Carrusel para pantallas grandes */}
      <div className="d-none d-md-flex justify-content-between align-items-center mb-5">
        <button className="btn btn-outline-primary" onClick={prevSlide}>
          &lt;
        </button>
        <div
          className="d-flex overflow-hidden flex-grow-1 position-relative"
          style={{ maxWidth: "100%" }}
        >
          <div
            className="d-flex"
            style={{
              transform: `translateX(-${
                (currentIndex % calificaciones.length) * (100 / 4)
              }%)`,
              transition: "transform 0.5s ease-in-out",
              minWidth: `${(calificaciones.length / 4) * 100}%`,
            }}
          >
            {calificaciones.map((calificacion, idx) => (
              <div
                key={idx}
                className="card mx-2"
                style={{
                  flex: "0 0 calc(25% - 1rem)",
                  maxWidth: "calc(25% - 1rem)",
                  minWidth: "calc(25% - 1rem)",
                }}
                onMouseEnter={() => setIsHovering(true)}
                onMouseLeave={() => setIsHovering(false)}
              >
                <div
                  className="card-header text-white d-flex justify-content-center"
                  style={{
                    fontSize: "1.2rem",
                    backgroundColor: "var(--primary-color)",
                  }}
                >
                  {Array.from({ length: calificacion.calificacion }).map(
                    (_, i) => (
                      <FaStar className="mx-1" key={i} />
                    )
                  )}
                </div>
                <div className="card-body text-center">
                  <blockquote className="blockquote">
                    <p>&ldquo;{calificacion.comentario}&rdquo;</p>
                  </blockquote>
                </div>
                <div className="card-footer text-end fst-italic">
                  {calificacion.nombre}
                </div>
              </div>
            ))}
          </div>
        </div>
        <button className="btn btn-outline-primary" onClick={nextSlide}>
          &gt;
        </button>
      </div>

      {/* Carrusel para pantallas pequeñas */}
      <div
        className="d-md-none overflow-auto rockola"
        style={{
          whiteSpace: "nowrap",
          display: "flex",
          overflowX: "scroll",
          gap: "1rem",
          WebkitOverflowScrolling: "touch", // Mejora el desplazamiento en dispositivos móviles
        }}
      >
        {calificaciones.map((calificacion, idx) => (
          <div
            key={idx}
            className="card d-inline-block mx-2"
            style={{
              flex: "0 0 60vw",
              height: "40vh", // Asegura el mismo alto para todas las tarjetas
              verticalAlign: "top",
              scrollSnapAlign: "center",
            }}
          >
            <div
              className="card-header text-white d-flex justify-content-center"
              style={{
                fontSize: "1.2rem",
                backgroundColor: "var(--primary-color)",
              }}
            >
              {Array.from({ length: calificacion.calificacion }).map((_, i) => (
                <FaStar className="mx-1" key={i} />
              ))}
            </div>
            <div
              className="card-body text-center"
              style={{
                height: "30vh", // Ajusta el alto del body
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <blockquote className="blockquote">
                <p>&ldquo;{calificacion.comentario}&rdquo;</p>
              </blockquote>
            </div>
            <div className="card-footer text-end fst-italic">
              {calificacion.nombre}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CalificacionesRocola;
