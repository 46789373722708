const certificacionesData = [
  {
    nombre: "Certificación EC0616",
    duracion: "6 meses",
    costo: "Inscripción: $300; Mensualidad: $1,200",
    descargable: "EC0616.pdf",
    imagen: "EC0616 AUXILIAR DE ENFERMERIA.png",
    temario: [
      {
        tema: "Enfermería propedéutica",
        descripcion:
          "Introducción a los fundamentos de la enfermería, estableciendo los principios básicos de la atención al paciente.",
      },
      {
        tema: "Anatomía",
        descripcion:
          "Estudio detallado de la estructura y organización del cuerpo humano y sus sistemas.",
      },
      {
        tema: "Procedimientos de atención al individuo",
        descripcion:
          "Práctica de técnicas esenciales para la atención individualizada y cuidados básicos del paciente.",
      },
      {
        tema: "Fisiopatologías",
        descripcion:
          "Exploración de las enfermedades comunes y cómo afectan el funcionamiento normal del cuerpo.",
      },
    ],
    beneficios:
      "Desarrolla habilidades críticas en el área de salud con validez SEP-CONOCER, mejora tu empleabilidad en hospitales y clínicas, y obtén experiencia práctica en centros de salud reconocidos.",
  },
  {
    nombre: "Certificación EC0076",
    duracion: "Información sin brindar",
    costo: "Información sin brindar",
    descargable: "EC0076.pdf",
    imagen: "EC076.png",
    temario: [
      {
        tema: "Técnicas de evaluación basadas en competencias",
        descripcion:
          "Estrategias de evaluación que aseguran el cumplimiento de estándares de competencia.",
      },
    ],
    beneficios:
      "Desarrolla competencias en la evaluación de candidatos en base a estándares reconocidos, posicionándote como evaluador profesional para industrias diversas y programas certificados.",
  },
  {
    nombre: "Certificación EC1375",
    duracion: "Información sin brindar",
    costo: "Información sin brindar",
    descargable: "EC1375.pdf",
    imagen: "EC1375.png",
    temario: [
      {
        tema: "Técnicas de recuperación física y emocional a través de métodos tradicionales y complementarios",
        descripcion:
          "Aplicación de métodos como masajes y terapias alternativas para el bienestar físico y emocional.",
      },
    ],
    beneficios:
      "Aumenta tus oportunidades en el sector de recuperación física y emocional mediante técnicas tradicionales y complementarias, mejorando tus habilidades para apoyar la salud integral de tus pacientes.",
  },
  {
    nombre: "Curso de Auxiliar de Enfermería",
    duracion: "6 meses",
    costo: "Inscripción: $300; Mensualidad: $1,200",
    descargable: "",
    imagen: "DIPLOMA ENFERMERIA.png",
    temario: [
      {
        tema: "Enfermería propedéutica",
        descripcion:
          "Introducción a los fundamentos de la enfermería, estableciendo los principios básicos de la atención al paciente.",
      },
      {
        tema: "Anatomía",
        descripcion:
          "Estudio detallado de la estructura y organización del cuerpo humano y sus sistemas.",
      },
      {
        tema: "Procedimientos de atención al individuo",
        descripcion:
          "Práctica de técnicas esenciales para la atención individualizada y cuidados básicos del paciente.",
      },
      {
        tema: "Fisiopatologías",
        descripcion:
          "Exploración de las enfermedades comunes y cómo afectan el funcionamiento normal del cuerpo.",
      },
    ],
    beneficios:
      "Desarrolla habilidades críticas en el área de salud con validez SEP-CONOCER, mejora tu empleabilidad en hospitales y clínicas, y obtén experiencia práctica en centros de salud reconocidos.",
  },
  {
    nombre: "Curso Ciencias Quirofísicas",
    duracion: "8 meses (4 horas/semana)",
    costo: "Información sin brindar",
    descargable: "",
    imagen: "DIPLOMA CIENCIAS QUIROFISICAS.png",
    temario: [
      {
        tema: "Anatomía y fisiología",
        descripcion:
          "Estudio de la anatomía humana y los procesos fisiológicos que mantienen el funcionamiento del cuerpo.",
      },
      {
        tema: "Osteología y miología",
        descripcion:
          "Análisis de los huesos y músculos, fundamentales en la práctica quirofísica.",
      },
      {
        tema: "Signos vitales",
        descripcion:
          "Monitoreo y evaluación de los signos vitales como base para el diagnóstico.",
      },
      {
        tema: "Hidroterapia",
        descripcion:
          "Técnicas de terapia mediante el uso de agua para aliviar molestias y mejorar la salud.",
      },
      {
        tema: "Quiropraxia",
        descripcion:
          "Métodos de ajuste y manipulación de la columna vertebral y otras áreas del cuerpo.",
      },
      {
        tema: "Medicina tradicional china",
        descripcion:
          "Introducción a prácticas como la acupuntura y la herbolaria, integrando enfoques de la medicina china.",
      },
    ],
    beneficios:
      "Especialízate en técnicas de rehabilitación y prevención, abriendo oportunidades en clínicas, centros deportivos y de rehabilitación. Tu formación integral hará la diferencia en el sector salud.",
  },
  {
    nombre: "Curso de Masajes",
    duracion: "10 meses",
    costo: "Inscripción: $300; Mensualidad: $900",
    descargable: "",
    imagen: "DIPLOMA MASAJES .png",
    temario: [
      {
        tema: "Anatomía",
        descripcion:
          "Comprensión de la estructura corporal, esencial para realizar masajes terapéuticos efectivos.",
      },
      {
        tema: "Drenaje linfático",
        descripcion:
          "Técnicas para estimular el sistema linfático, ayudando a la eliminación de toxinas.",
      },
      {
        tema: "Masaje sueco",
        descripcion:
          "Método de masaje relajante que utiliza movimientos suaves y deslizantes.",
      },
      {
        tema: "Masaje tailandés",
        descripcion:
          "Terapia que combina presiones y estiramientos para mejorar la flexibilidad y reducir el estrés.",
      },
      {
        tema: "Shiatsu",
        descripcion:
          "Masaje japonés basado en la presión de puntos específicos para el alivio de dolencias.",
      },
      {
        tema: "Reflexología",
        descripcion:
          "Masaje terapéutico en manos y pies que se basa en puntos reflejos conectados con otras partes del cuerpo.",
      },
    ],
    beneficios:
      "Obtén conocimientos en técnicas de masaje terapéutico con respaldo profesional. Recibe asesoramiento para emprender tu propio centro de bienestar, con apoyo continuo y oportunidades de crecimiento.",
  },
  {
    nombre: "Diplomado en Quiropráctica",
    duracion: "1 año",
    costo: "Inscripción: $250; Mensualidad: $1,000",
    descargable: "",
    imagen: "DIPLOMA QUIROPRACTICA.png",
    temario: [
      {
        tema: "Anatomía",
        descripcion:
          "Estudio de la estructura anatómica del cuerpo humano, con enfoque en sistemas relacionados con la quiropráctica.",
      },
      {
        tema: "Osteología",
        descripcion:
          "Exploración de los huesos y su relación con la práctica quiropráctica.",
      },
      {
        tema: "Masaje sueco",
        descripcion:
          "Técnica de masaje utilizada en quiropráctica para la relajación muscular.",
      },
      {
        tema: "Patologías de columna",
        descripcion:
          "Estudio de las enfermedades y trastornos que afectan la columna vertebral.",
      },
      {
        tema: "Ejercicio terapéutico",
        descripcion:
          "Prácticas de ejercicios que mejoran la postura y fortalecen el sistema musculoesquelético.",
      },
      {
        tema: "Quiropráctica I, II, III",
        descripcion:
          "Series de técnicas avanzadas en ajustes y manipulaciones quiroprácticas.",
      },
    ],
    beneficios:
      "Desarrolla habilidades en quiropráctica con una formación integral en aspectos anatómicos y biomecánicos. Podrás abrir tu propio consultorio o trabajar en equipos deportivos y centros de rehabilitación.",
  },
  {
    nombre: "Certificación EC0217.01",
    duracion: "Información sin brindar",
    costo: "Información sin brindar",
    descargable: "EC0217.01.pdf",
    imagen: "",
    temario: [
      {
        tema: "Desarrollo de habilidades para impartir capacitación presencial grupal",
        descripcion:
          "Capacitación para dirigir cursos en grupo, mejorando técnicas de enseñanza y liderazgo.",
      },
    ],
    beneficios:
      "Certificación oficial que te acredita para impartir formación presencial en grupos, fortaleciendo tus habilidades de enseñanza y aumentando tu perfil profesional en capacitación.",
  },
  {
    nombre: "Certificación EC0301",
    duracion: "Información sin brindar",
    costo: "Información sin brindar",
    descargable: "ec301.pdf",
    imagen: "",
    temario: [
      {
        tema: "Diseño de cursos",
        descripcion:
          "Metodologías para estructurar cursos eficaces y de impacto.",
      },
      {
        tema: "Elaboración de instrumentos de evaluación y manuales",
        descripcion:
          "Creación de herramientas para medir y guiar el aprendizaje de los estudiantes.",
      },
    ],
    beneficios:
      "Amplía tus capacidades para diseñar e impartir cursos estructurados de manera efectiva, siendo reconocido como un experto en la creación de contenidos y evaluaciones para el desarrollo humano.",
  },
  {
    nombre: "Certificación EC0366",
    duracion: "Información sin brindar",
    costo: "Información sin brindar",
    descargable: "ec366.pdf",
    imagen: "",
    temario: [
      {
        tema: "Creación y estructuración de programas de formación en entornos virtuales",
        descripcion:
          "Diseño de cursos en línea, aprovechando herramientas digitales para una enseñanza efectiva.",
      },
    ],
    beneficios:
      "Obtén una certificación en la educación en línea, capacitándote para estructurar cursos virtuales. Amplía tus oportunidades en la formación digital y mejora tus habilidades en entornos de aprendizaje virtual.",
  },
];

export default certificacionesData;
