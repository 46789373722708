// AvisoDePrivacidad.js
import React from "react";

function AvisoDePrivacidad() {
  return (
    <div className="container py-5">
      <div class="container">
        <br />
        <h2>
          Aviso de Privacidad para Centros de Evaluación y Centros
          Certificadores Microsoft
        </h2>
        <br />
        <h3>Perfil “Candidatos para Certificación de Competencias”</h3>
        <br />
        <h3>Fundamento Legal</h3>

        <p>
          El presente Aviso de Privacidad (aviso) se emite en cumplimiento por
          lo dispuesto por el artículo 15 y 17 de la Ley Federal de Protección
          de Datos Personales en Posesión de los Particulares (LFPDPPP), de los
          Lineamientos del Aviso de Privacidad en sus artículos 6, 9,11 y 17 y
          del artículo 23, 25 y 31 de su Reglamento (Principio de Información).
        </p>

        <h3>Datos del Responsable</h3>
        <p>
          FUNDACIÓN NICAN, A.C., conocido comercialmente como "Centro
          capacitador y evaluador Nican" con domicilio en CALLE LINDEROS DE
          IXTAPALUCA POZO 9; es responsable del tratamiento de sus datos
          personales conforme a este Aviso de Privacidad.
        </p>
        <p>Datos de Contacto: Departamento de Protección de Datos</p>
        <ul>
          <li>Responsable: DEYSI VALENCIA GARCIA</li>
          <li>Oficial de Protección de Datos Personales</li>
          <li>Teléfono: 55-79-11-85-89</li>
          <li>
            Correo Electrónico:{" "}
            <a href="mailto:centro.nican@hotmail.com">
              centro.nican@hotmail.com
            </a>
          </li>
        </ul>

        <h3>
          ¿Cómo Garantizamos la Seguridad y el Tratamiento de sus Datos
          Personales?
        </h3>
        <p>Mediante un Programa Integral de Cumplimiento</p>
        <p>
          De conformidad a lo dispuesto al artículo 19 de la Ley, en lo
          dispuesto al artículo 57 del Reglamento y siguiendo las
          recomendaciones publicadas en el DOF el 30 de octubre de 2013 sobre
          las recomendaciones en materia de seguridad de datos personales hemos
          implementado Sistema de Gestión de Seguridad de Datos Personales
          (SGSDP) con la finalidad de asegurar el tratamiento correcto de sus
          datos personales y poder garantizar el cumplimiento de los Principios
          Rectores de la Ley, la Confidencialidad, el Deber de Seguridad y la
          atención a sus Derechos ARCO. Aunado a lo anterior y con el objetivo
          de cumplir con las obligaciones derivadas del tratamiento de los datos
          personales en el ámbito privado y público. El Organismo Certificador,
          ha adoptado un esquema de autorregulación que permita garantizar el
          derecho a la privacidad y a la protección de datos personales, por lo
          tanto; Se apega estrictamente a las mejores prácticas y procedimientos
          regulados por el centro de acreditación y Red CONOCER.
        </p>

        <h3>¿Qué Datos Personales Recabamos y Para Qué Fines?</h3>
        <p>
          Centro capacitador y evaluador Nican efectuará el correcto tratamiento
          de sus “Datos Personales " que sean necesarios para la adecuada
          prestación de nuestros servicios, mediante el cual obtendremos sus
          datos personales por los siguientes medios: (1) directa o
          personalmente, (2) correo electrónico, (3) Campañas Digitales, (4)
          Mediante Mensajería Instantánea (5) Formularios y Encuestas, (6)a
          través de su formulario de contacto en su página oficial web
          www.nican.com ,( 7 ) vía telefónica y/o a través de nuestras redes
          sociales, (8) plataformas y pasarelas digitales.
        </p>

        <h4>¿Qué Datos Personales Tratamos?</h4>
        <p>
          <strong>Usuarios:</strong>
        </p>
        <ul>
          <li>Nombre completo</li>
          <li>Teléfono(s) contacto</li>
          <li>Correo Electrónico</li>
          <li>Estado</li>
          <li>Ciudad</li>
        </ul>
        <p>
          <strong>Centros:</strong>
        </p>
        <ul>
          <li>Nombre completo</li>
          <li>Teléfono(s) contacto</li>
          <li>Domicilio Particular</li>
          <li>Fecha de Nacimiento</li>
          <li>Estado Civil</li>
          <li>Si tienen hijos o no</li>
          <li>Nacionalidad</li>
          <li>Estado</li>
          <li>Ciudad</li>
          <li>Correo Electrónico</li>
          <li>Género</li>
          <li>RFC</li>
          <li>CURP</li>
          <li>Firma</li>
          <li>Datos relacionados a la Identificación Oficial</li>
          <li>Datos laborales (empleado o desempleados)</li>
          <li>Nivel de puesto</li>
          <li>
            Datos de la empresa donde labora (pyme, mediana o gran
            empresa)(quejas, qué te gusta de tu trabajo)
          </li>
          <li>Datos demográficos (sector productivo al que pertenecen)</li>
          <li>Grado de estudios</li>
          <li>Carrera profesional</li>
          <li>
            Datos psicográficos (sentimientos laborales, deseos, sentimientos y
            miedos personales)
          </li>
          <li>Certificaciones CONOCER</li>
          <li>Información sobre el interés de la certificación</li>
        </ul>

        <h4>Finalidades Primarias:</h4>
        <h5>Usuarios:</h5>
        <ul>
          <li>Identificación y contacto</li>
          <li>
            Campañas de orientación y formación sobre certificación Microsoft
          </li>
          <li>Atención en Plataformas Digitales</li>
        </ul>
        <h5>Centros:</h5>
        <ul>
          <li>Identificación y contacto </li>
          <li>
            Campañas de orientación y formación sobre la certificación
            Microsoft.
          </li>
          <li>Atención en Plataformas Digitales</li>
          <li>Grabar las reuniones con fines de consulta y aprendizaje</li>
          <li>Emitir presupuestos y cotizaciones </li>
          <li>Orientación sobre el proceso de capacitación y certificación</li>
          <li>Registro del candidato en MICROSOFT</li>
          <li>Registro del candidato en CONOCER </li>
          <li>Encuestas de satisfacción y calidad</li>
          <li>Gestionar el certificado MOS (Microsoft Office Specialist) </li>
          <li>Gestionar el certificado de competencias </li>
          <li>
            Enviar recordatorios por mensajería instantánea (WhatsApp, Telegram,
            Correo Electrónico)
          </li>
          <li>Atender quejas y sugerencias </li>
          <li>Atención y gestión a las solicitudes de sus Derechos ARCO</li>
          <li>
            Cumplir con las obligaciones en materia de protección de datos en
            posesión de Sujetos Obligados Ámbito privado como: (ICEMéxico){" "}
          </li>
          <li>
            Conservar su Información para el cumplimiento de las disposiciones
            legales y requerimientos de diversas autoridades regulatorias.
          </li>
        </ul>

        <h4>Finalidades Secundarias:</h4>
        <ul>
          <li>Encuestas de desarrollo profesional y calidad</li>
          <li>Prospección comercial</li>
          <li>Informe sobre estudios de investigación educativa</li>
          <li>Envío de información de Microsoft y análisis demográficos</li>
          <li>
            Evidencia fotográfica y video de eventos presenciales y virtua
          </li>
          <li>Envío de información actualizada de Microsoft.</li>
          <li>VAnálisis demográficos y psicográficos.</li>
        </ul>

        <h3>Datos Sensibles</h3>
        <p>
          Centro capacitador y evaluador Nican para cumplir las finalidades
          previstas en este Aviso de Privacidad, le informa que No recabará y No
          tratará datos sensibles.
        </p>

        <h3>Consentimiento del Titular</h3>
        <p>
          ● Habiéndose puesto a disposición del Titular este Aviso de Privacidad
          y sus actualizaciones, ya sea personalmente o a través de la página de
          internet o de otros medios, y no habiendo manifestado inmediatamente
          oposición alguna, se entenderá que el titular ha consentido
          tácitamente el tratamiento de sus datos.
        </p>
        <p>
          ● Si el Titular proporcionó sus datos verbalmente, físicamente, por
          escrito, por medios electrónicos, ópticos o por cualquier otra
          tecnología, o por signos inequívocos, se entenderá que ha dado su
          consentimiento expreso del uso de dicha información.
        </p>
        <p>
          Centro capacitador y evaluador Nican Por su parte el titular declara
          que sus datos personales otorgados son exactos, auténticos y
          completos, dando consentimiento a verificar la veracidad de los
          mismos.
        </p>

        <h3>
          ¿Con Quién Compartimos Su Información Personal y Para Qué Fines?
        </h3>

        <h4>Transferencias:</h4>
        <p>
          hace del conocimiento del Titular que los datos personales pueden ser
          remitidos dentro y fuera del país, con el objeto de dar cumplimiento a
          las obligaciones jurídicas y/o comerciales, para lo cual hemos
          celebrado o celebraremos diversos acuerdos comerciales tanto en
          territorio nacional como en el extranjero. Los receptores de los datos
          personales están obligados a mantener las medidas previstas por la
          Legislación aplicable, el contrato correspondiente, así como por el
          presente Aviso de Privacidad, los datos personales suministrados por
          el responsable.
        </p>
        <p>
          Aunado a lo anterior, se hace de su conocimiento que en podremos
          transferir datos personales a petición de las autoridades competentes
          con la finalidad de atender requerimientos que estén debidamente
          fundados, motivados y con un mandato legal.
        </p>
        <p>
          Ante este escenario hacemos de su conocimiento que dentro de los
          terceros a los que se transferirán dichos datos se incluyen
          dependencias de gobierno, federales, estatales y municipales, así como
          a cualquier empresa del mismo grupo o aquellos que Centro capacitador
          y evaluador Nican considere necesario o conveniente comunicar por una
          relación contractual de atención a titulares de los datos personales;
          esta comunicación se da manteniendo su estricta confidencialidad y
          responsabilidad entre las partes involucradas. Si usted no manifiesta
          su oposición para que sus datos sean transferidos, se entenderá que ha
          otorgado su consentimiento para ello.
        </p>

        <h3>Medios para Ejercer Derechos ARCO</h3>
        <h5>
          ¿Cómo puede acceder, rectificar o cancelar sus datos personales, u
          oponerse a su uso?{" "}
        </h5>
        <p>
          Usted tiene derecho a conocer qué datos personales tenemos de usted,
          para qué los utilizamos y las condiciones del uso que les damos
          (Acceso), así mismo, es su derecho solicitar la corrección de su
          información personal en caso de que esté desactualizada, sea inexacta
          o incompleta (Rectificación); que la eliminemos de nuestros registros
          o bases de datos cuando considere que la misma no está siendo
          utilizada conforme a los principios, deberes y obligaciones previstas
          en la normativa (Cancelación); así como oponerse al uso de sus datos
          personales para fines específicos (Oposición). Estos derechos se
          conocen como Derechos ARCO.
        </p>
        <p>
          Para el ejercicio de cualquiera de los derechos ARCO, usted deberá
          presentar la solicitud respectiva en el domicilio del responsable
          descrito en este Aviso de Privacidad por medio de un escrito libre o
          formato de derecho ARCO dirigida a nuestro Departamento de Protección
          de Datos Personales de lunes a viernes de 9:00am a 7:00pm
          solicitándole sus derechos a ejercer.
        </p>
        <p>
          Adicionalmente le solicitamos que presente la siguiente información y
          adjunte los documentos que se enlistan a continuación en original y
          copia:
        </p>

        <p>
          <ul>
            <li>a. Nombre del titular</li>
            <li>
              b. Domicilio de titular o dirección de correo electrónico para
              comunicar respuesta a solicitud
            </li>
            <li>
              c. Documentos que acrediten identidad o autorización para
              representarlo en la solicitud.
            </li>
            <li>
              d. Descripción de datos personales sobre los que se pretende
              ejercer algún derecho ARCO
            </li>
            <li>
              e. Cualquier otro elemento que permita la localización de los
              datos personales y atención a la solicitud
            </li>
          </ul>
        </p>
        <p>
          Para conocer el procedimiento y requisitos para el ejercicio de los
          Derechos ARCO, usted podrá llamar al siguiente número telefónico
          5579118589 o bien ponerse en contacto con nuestro Departamento de
          Protección de Datos Personales, que dará trámite a las solicitudes
          para el ejercicio de estos derechos, y atenderá cualquier duda que
          pudiera tener respecto al tratamiento de su información.
        </p>
        <h3>Limitación de Uso y Divulgación de Datos Personales</h3>
        <p>
          El responsable y/o sus Encargados conservarán los datos personales
          durante el tiempo que sea necesario para procesar sus solicitudes de
          información, productos y/o servicios, así como para mantener los
          registros contables, financieros y de auditoría en términos de la
          LFPDPPP y de la legislación mercantil, fiscal y administrativa
          vigente. Además de controlar la divulgación de dichos datos o
          información de socios comerciales. Usted o su representante legal
          debidamente acreditado podrán limitar el uso o divulgación de sus
          datos personales a través de los mismos medios y procedimientos
          dispuestos para el ejercicio de los Derechos ARCO. Una vez que su
          solicitud resulte procedente, será registrado en el listado de
          exclusión dispuesto por Centro capacitador y evaluador Nican con la
          finalidad de que usted deje de recibir información relativa a campañas
          publicitarias o de mercadotecnia.
          <br />
          <b>
            Con objeto de que usted pueda limitar el uso y divulgación de su
            información personal, le ofrecemos los siguientes medios:
          </b>
          <br />
          🗹 Solicitar su registro al listado de limitación de uso con la
          finalidad de que sus datos personales no sean tratados para fines
          mercadotécnicos, publicitarios o de prospección comercial por nuestra
          parte. Para mayor información llamar al número 5579118589 o enviar un
          correo electrónico a:
          <a href="mailto:fundacionnican2020@hotmail.com">
            fundacionnican2020@hotmail.com
          </a>
          o notificación por escrito entregado en el domicilio del responsable.
        </p>

        <h3>Notificación de Cambios al Aviso de Privacidad</h3>
        <p>
          El presente aviso de privacidad puede sufrir modificaciones, cambios o
          actualizaciones derivadas de nuevos requerimientos legales, de
          nuestras propias necesidades por los productos o servicios que
          ofrecemos, de nuestras prácticas de privacidad; de cambios en nuestro
          modelo de negocio, o por otras causas y podrá ser modificado en
          cualquier momento para cumplir con actualizaciones legislativas,
          jurisprudenciales, políticas internas, nuevos requisitos para la
          prestación de servicios de Centro capacitador y evaluador Nican o
          cualquier otra causa a entera discreción. En tal caso las
          modificaciones estarán disponibles en las oficinas señaladas en este
          aviso y a través de nuestro sitio web www.nican.com
        </p>

        <h3>Aviso de Privacidad del Sistema Nacional de Competencias</h3>
        <p>
          Si usted desea conocer el aviso de privacidad de ICEMéxico, puede
          dirigirse directamente a la página web
          <a href="http://icemexico.com" target="_blank">
            icemexico.com
          </a>{" "}
          y seleccionar en el apartado “Aviso de Privacidad”.
          <br />
          Si usted desea conocer el aviso de privacidad de CONOCER, puede
          dirigirse directamente a la página web{" "}
          <a href="https://conocer.gob.mx" target="_blank">
            conocer.gob.mx
          </a>
          y seleccionar en el apartado “Protección de Datos Personales” o
          seleccionar el siguiente link de acceso directo:
          <a
            href="https://conocer.gob.mx/documentos/proteccion-de-datos-personales/"
            target="_blank"
          >
            https://conocer.gob.mx/documentos/proteccion-de-datos-personales/
          </a>
        </p>

        <p>Fecha de última actualización: 21 de julio de 2022.</p>
      </div>
    </div>
  );
}

export default AvisoDePrivacidad;
