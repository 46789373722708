import React, { useEffect, useState } from "react";
import "../styles/InscripcionPasos.css";

function InscripcionPasos() {
  const [stepIndex, setStepIndex] = useState(0);

  const steps = [
    "Elige la certificación que te impulse hacia el futuro que deseas.",
    "Envíanos una copia de tu INE, CURP y comprobante de domicilio. ¡Solo unos documentos para comenzar!",
    "Realiza la transferencia de inscripción y asegura tu lugar en el programa.",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setStepIndex((prevIndex) =>
        prevIndex < steps.length ? prevIndex + 1 : prevIndex
      );
    }, 1300);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="inscripcion-pasos-container py-4 my-5" data-aos="fade-up">
      <h2 className="text-center text-white mb-4">
        Pasos para Comenzar Tu Certificación
      </h2>
      <div className="steps-wrapper my-5">
        {steps.map((step, index) => (
          <div
            key={index}
            data-aos="fade-up"
            data-aos-delay={index * 100}
            className={`step-circle ${
              index <= stepIndex ? "visible" : "hidden"
            }`}
          >
            <h5 className="my-2">Paso {index + 1}</h5> {/* Incremental title */}
            <p className="step-text">{step}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default InscripcionPasos;
