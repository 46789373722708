import React, { useState } from "react";
import fundacionNicanImg from "../imgs/formulario/fundacion-nican.png";
import { Form, Button, Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import "../styles/ContactForm.css";

const whatsappNumber = "5579118589";

function ContactForm() {
  const [formData, setFormData] = useState({
    nombre: "",
    curso: "",
    mensaje: "",
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    Swal.fire({
      title: "¡Abriremos WhatsApp!",
      text: "Enviaremos un mensaje sin compromiso para ofrecerte un cupón de descuento. ¡No dudes en contactarnos!",
      icon: "info",
      confirmButtonText: "Aceptar",
      confirmButtonColor: "var(--primary-color)",
    }).then((result) => {
      if (result.isConfirmed) {
        const message = `Hola, soy ${formData.nombre} y estoy interesado/a en el curso ${formData.curso}. 
                          Me gustaría certificarme porque: ${formData.mensaje}. 
                          ¿Podrían proporcionarme más información y un cupón de descuento, por favor?`;
        window.open(
          `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(message)}`,
          "_blank"
        );
      }
    });
  };

  return (
    <section
      id="contact"
      className="py-5 contact-form-section  my-5"
      data-aos="fade-right"
    >
      <div className="container">
        <div className="row mb-4">
          <h2 className="text-center my-5">
            Cuéntanos por qué estás buscando una certificación o diplomado y
            obtén un descuento.
          </h2>
        </div>
        <div className="row mt-5">
          {/* Columna Izquierda - Imagen */}
          <div className="col-md-6 d-flex align-items-center">
            <img
              src={fundacionNicanImg}
              alt="Fundación Nican"
              className="img-fluid w-100 h-100"
              style={{ objectFit: "cover", borderRadius: "15px" }}
            />
          </div>

          {/* Columna Derecha - Formulario */}
          <div className="col-md-6">
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="nombre" className="mb-3">
                <Form.Label>Nombre</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Tu nombre"
                  value={formData.nombre}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group controlId="curso" className="mb-3">
                <Form.Label>¿En qué curso estás interesado?</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Nombre del curso o diplomado"
                  value={formData.curso}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group controlId="mensaje" className="mb-3">
                <Form.Label>Cuéntanos por qué quieres certificarte</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Escribe aquí tu motivación"
                  value={formData.mensaje}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Button variant="primary" type="submit" className="w-100">
                Obtener descuento
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactForm;
