import React, { useEffect, useState, useRef } from "react";
import Img_1 from "../imgs/personas-ayudamos/Auxiliar-Enfermeria.jpg";
import Img_2 from "../imgs/personas-ayudamos/Auxiliar-Enfermeria_2.jpeg";
import Img_3 from "../imgs/personas-ayudamos/Auxiliar-Enfermeria_3.png";
import Img_4 from "../imgs/personas-ayudamos/Auxiliar-Enfermeria_4.jpg";
import Img_5 from "../imgs/personas-ayudamos/Auxiliar-Enfermeria_5.png";
import Img_6 from "../imgs/personas-ayudamos/Auxiliar-Enfermeria_6.png";
import Img_7 from "../imgs/personas-ayudamos/Auxiliar-Enfermeria_7.jpg";
import Img_8 from "../imgs/personas-ayudamos/Auxiliar-Enfermeria_8.png";
import "../styles/AQuienesBuscamos.css";
import { FaCheckCircle } from "react-icons/fa";

function AQuienesBuscamos() {
  const items = [
    {
      text: "Personas que quieren aumentar sus ingresos",
      img: Img_1,
    },
    {
      text: "Personas que nunca paran de aprender",
      img: Img_2,
    },
    {
      text: "Personas comprometidas con su crecimiento",
      img: Img_3,
    },
    {
      text: "Personas que buscan un impacto positivo",
      img: Img_4,
    },
    {
      text: "Personas que valoran la educación continua",
      img: Img_5,
    },
    {
      text: "Personas con espíritu de superación",
      img: Img_6,
    },
    {
      text: "Personas que desean hacer la diferencia",
      img: Img_7,
    },
    {
      text: "Personas con pasión por el aprendizaje",
      img: Img_8,
    },
  ];
  const intervalTime = 1800;

  const [currentIndex, setCurrentIndex] = useState(0);
  const [visibleItems, setVisibleItems] = useState([]);
  const [currentImage, setCurrentImage] = useState(items[0].img); // Inicializa con la primera imagen
  const elementRef = useRef(null);
  const [inView, setInView] = useState(false);

  useEffect(() => {
    if (!inView) return;

    const interval = setInterval(() => {
      if (currentIndex < items.length) {
        setCurrentImage(items[currentIndex].img);
        setVisibleItems((prev) => [...prev, items[currentIndex].text]);
        setCurrentIndex((prev) => prev + 1);
      } else {
        clearInterval(interval);
      }
    }, intervalTime);

    return () => clearInterval(interval);
  }, [inView, currentIndex, intervalTime]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;
        setInView(entry.isIntersecting);
      },
      { threshold: 0.5 }
    );

    if (elementRef.current) observer.observe(elementRef.current);

    return () => {
      if (elementRef.current) observer.unobserve(elementRef.current);
    };
  }, []);

  return (
    <section
      id="a-quienes-buscamos"
      className="a-quienes-buscamos-section py-5 bg-light"
      ref={elementRef}
    >
      <div className="container">
        <h2 className={`my-3 text-center ${inView ? "fade-in" : ""}`}>
          ¿A quiénes ayudamos?
        </h2>
        <div className="row d-flex align-items-center mt-4">
          {/* Columna Izquierda */}
          <div className="col-lg-5 col-md-12 text-list d-flex flex-column justify-content-between">
            <ul>
              {visibleItems.map((item, index) => (
                <li key={index} className={inView ? "fade-in" : ""}>
                  <FaCheckCircle className="check-icon" /> {item}
                </li>
              ))}
            </ul>
          </div>
          {/* Columna Derecha */}
          <div className="col-lg-7 col-md-12 text-center">
            {currentImage && (
              <img
                src={currentImage}
                alt="current"
                className={`shadow  a-quienes-buscamos-image ${
                  inView ? "fade-in" : ""
                }`}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default AQuienesBuscamos;
