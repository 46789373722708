import React, { useEffect } from "react";
import fundacionNicanImg from "../imgs/quienes-somos/nican.jpg";
import AOS from "aos";
import "aos/dist/aos.css";
import "../styles/AboutUs.css";

function AboutUs() {
  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);

  return (
    <section id="about" className="about-section my-5" data-aos="fade-up">
      <div className="container h-100 d-flex align-items-center mt-5 mt-md-0">
        <div className="row w-100">
          {/* Columna Izquierda */}
          <div
            className="col-lg-7 text-justify about-text p-3"
            data-aos="fade-right"
          >
            <h2>¿Por qué confiar en Fundación Nican?</h2>
            <p>
              En un mundo en constante evolución, la capacitación profesional es
              fundamental para quienes desean destacarse en el ámbito de la
              salud y el bienestar. En Fundación Nican A.C., formamos
              especialistas con un enfoque integral, desarrollando competencias
              clave que permiten a nuestros alumnos mejorar la calidad de vida
              de sus pacientes y ser verdaderos agentes de cambio.
            </p>
            <p>
              Nuestros programas de formación en Ixtapaluca, Estado de México,
              están diseñados para ofrecer una educación completa y respaldada
              por instituciones reconocidas, como la SEP. Esto garantiza que
              nuestros egresados adquieran las habilidades necesarias con un
              respaldo oficial, aumentando su credibilidad y potencial en el
              mercado laboral.
            </p>
          </div>

          {/* Columna Derecha */}
          <div className="col-lg-5 d-none d-lg-block" data-aos="fade-left">
            <img
              src={fundacionNicanImg}
              alt="Capacitación Fundación Nican | Ixtapaluca , Estado de México"
              className="about-image"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutUs;
