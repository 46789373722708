import React from "react";
import { FaFacebook } from "react-icons/fa";
import "../styles/Footer.css";

function Footer() {
  return (
    <footer
      className="py-4 pb-5"
      style={{ backgroundColor: "var(--secondary-color)", color: "white" }}
    >
      <div className="container">
        <div className="row text-center mb-3">
          <p>&copy; 2024 Fundación Nican A.C.</p>
        </div>
        <div className="row text-md-left ">
          {/* Columna 1 */}
          <div className="col-md-4 mb-3 mb-md-0 text-sm-center  text-md-left ">
            <a
              href="/AvisoDePrivacidad"
              style={{ color: "white", textDecoration: "none" }}
            >
              Aviso de Privacidad
            </a>
            <br />
            <br />
            <a
              href="mailto:fundacionnican2020@hotmail.com?subject=Quejas%20y%20Sugerencias"
              className="my-2"
              style={{
                color: "white",
                textDecoration: "underline",
                marginBottom: 4,
              }}
            >
              Buzón de Quejas y Sugerencias
            </a>
            <br />
            <br />
            <a
              href="/Conocer"
              className="my-2"
              style={{
                color: "white",
                textDecoration: "underline",
                marginBottom: 4,
              }}
            >
              Conocer
            </a>
          </div>

          {/* Columna 2 */}
          <div className="col-md-4 mb-3 mb-md-0 text-center ">
            <h5>Visítanos en nuestras redes sociales</h5>
            <a
              href="https://www.facebook.com/centro.Nican"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "white", fontSize: "1.5rem" }}
              className="float-icon"
            >
              <FaFacebook />
            </a>
          </div>

          {/* Columna 3 */}
          <div className="col-md-4 text-sm-center ">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3761.2180142510426!2d-98.8636925!3d19.3384195!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85cd888c50a1532d%3A0x4ff7b6fcaeeb2c4!2sLinderos%20de%20Ixtapaluca%20Pozo%209%2C%20Ixtapaluca%2C%20Mexico%2C%2056530!5e0!3m2!1ses!2smx!4v1638299084296!5m2!1ses!2smx"
              width="100%"
              height="100"
              style={{ border: 0, borderRadius: "8px", marginBottom: "10px" }}
              allowFullScreen=""
              loading="lazy"
              title="Ubicación Fundación Nican"
            ></iframe>
            <p>
              linderos de ixtapaluca pozo 9, 56530 Ixtapaluca, Estado de México,
              México
            </p>
            <p className="footer-small-text">
              Mayor referencia: a 5 minutos del hospital de especialidades, en
              dirección a San Francisco Acuautla.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
