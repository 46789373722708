import React, { useState, useEffect } from "react";
import { FaWhatsapp } from "react-icons/fa";
import "../styles/FloatingWhatsApp.css";

const whatsappNumber = "5579118589";
const message =
  "Hola, me gustaría recibir más información sobre sus cursos. ¿me podrían ayudar?";
const typingText = "¿Necesitas más información? Contáctanos sin compromiso.";

function FloatingWhatsApp() {
  const [displayedText, setDisplayedText] = useState("");
  const [typingIndex, setTypingIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(false); // Controla la visibilidad

  useEffect(() => {
    const visibilityTimeout = setTimeout(() => {
      setIsVisible(true); // Aparece después de 25 segundos
    }, 20000);

    return () => clearTimeout(visibilityTimeout); // Limpia el temporizador al desmontar el componente
  }, []);

  useEffect(() => {
    if (!isVisible) return; // No iniciar el efecto de escritura hasta que sea visible

    const typingInterval = setInterval(() => {
      if (typingIndex < typingText.length) {
        setDisplayedText((prevText) => prevText + typingText[typingIndex]);
        setTypingIndex((prevIndex) => prevIndex + 1);
      }
    }, 150); // Velocidad de la "máquina de escribir"

    return () => clearInterval(typingInterval);
  }, [typingIndex, isVisible]);

  const handleWhatsAppClick = () => {
    window.open(
      `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(message)}`,
      "_blank"
    );
  };

  if (!isVisible) return null; // No renderizar el componente hasta que pase el tiempo

  return (
    <div className="floating-whatsapp" onClick={handleWhatsAppClick}>
      <FaWhatsapp className="whatsapp-icon" />
      <div className="floating-text">{displayedText}</div>
    </div>
  );
}

export default FloatingWhatsApp;
