import React, { useEffect, useState, useRef } from "react";
import "../styles/Services.css";
import { FaChartLine, FaBriefcase, FaGraduationCap } from "react-icons/fa";

function StatisticCard({ icon: Icon, title, target, text }) {
  const [count, setCount] = useState(0);
  const [hasCounted, setHasCounted] = useState(false); // Variable de control
  const elementRef = useRef(null);

  useEffect(() => {
    const handleScroll = (entries) => {
      const [entry] = entries;
      if (entry.isIntersecting && !hasCounted) {
        let start = 0;
        const end = target;
        if (start === end) return;

        const incrementTime = Math.floor(2000 / end); // Duración de 2 segundos
        const timer = setInterval(() => {
          start += 1;
          setCount(start);
          if (start === end) clearInterval(timer);
          setHasCounted(true); // Marca como contado para evitar que se repita
        }, incrementTime);
      }
    };

    const observer = new IntersectionObserver(handleScroll, {
      threshold: 0.5,
    });

    if (elementRef.current) observer.observe(elementRef.current);

    return () => {
      if (elementRef.current) observer.unobserve(elementRef.current);
    };
  }, [target, hasCounted]);

  return (
    <div className="col-md-4 mb-4 ServicesCard-Item" ref={elementRef}>
      <div className="card service-card" data-aos="fade-up">
        <div className="card-header">
          <Icon className="service-icon" />
          <div className="mx-5">
            <h3 className="service-number">{count}%</h3>
            <h4 className="service-title">{title}</h4>
          </div>
        </div>
        <div className="card-body">
          <p>{text}</p>
        </div>
      </div>
    </div>
  );
}

function Services() {
  return (
    <section id="services" className="services-section py-5 bg-light">
      <div className="container">
        <h2 className="text-center mb-2">En Fundación Nican A.C.</h2>
        <h5 className="my-2 text-center mb-5">
          Nuestros números avalan nuestra calidad, pasión y compromiso
        </h5>
        <div className="row">
          <StatisticCard
            icon={FaChartLine}
            title="Aumentan sus ingresos"
            target={88}
            text="El 88% de nuestros estudiantes experimentan un aumento significativo en sus ingresos tras capacitarse con nosotros. Esto refleja el valor práctico de nuestros programas, orientados a brindar herramientas que son directamente aplicables en el mercado laboral. Nuestra fundación se enfoca en crear especialistas que puedan generar un cambio tangible en sus áreas, logrando un impacto positivo en su vida profesional y económica. A través de metodologías adaptadas a las necesidades del sector, facilitamos que los estudiantes ganen competencias valoradas en el mundo laboral."
          />
          <StatisticCard
            icon={FaBriefcase}
            title="Consiguen mejor empleo"
            target={79}
            text="El 79% de nuestros egresados consigue un empleo de mayor calidad, resultado de nuestro enfoque en formación y certificación. Los programas están diseñados para proporcionar conocimientos prácticos que no solo aumentan la empleabilidad, sino que mejoran las condiciones de nuestros alumnos en sus respectivos campos. Ofrecemos una formación orientada al mercado laboral, y nuestros estudiantes obtienen un respaldo que les permite competir en un sector creciente y cada vez más profesionalizado."
          />
          <StatisticCard
            icon={FaGraduationCap}
            title="Terminan su capacitación"
            target={98}
            text="Fundación Nican cuenta con una tasa de finalización del 98%, una muestra del apoyo y el compromiso que brindamos a nuestros estudiantes. Este logro se debe a nuestro enfoque centrado en el alumno, donde cada uno de ellos es acompañado en su camino para completar su capacitación. Nos aseguramos de que dispongan de los recursos y el soporte necesarios para enfrentar sus desafíos educativos. De esta manera, nuestros estudiantes terminan su formación listos para poner en práctica sus conocimientos."
          />
        </div>
      </div>
    </section>
  );
}

export default Services;
