import React from "react";
import Swal from "sweetalert2";
import "../styles/InvitacionDescuento.css";

const whatsappNumber = "5579118589";

function InvitacionDescuento() {
  const handleWhatsApp = () => {
    Swal.fire({
      title: "¡Obtén un cupón sin compromiso!",
      text: "Al enviar este mensaje, obtendrás un cupón exclusivo para un mejor futuro sin ningún compromiso.",
      icon: "info",
      confirmButtonText: "Aceptar",
      confirmButtonColor: "var(--primary-color)",
    }).then((result) => {
      if (result.isConfirmed) {
        const message = `Hola, estoy interesado en compartir el contacto de una persona que podría estar interesada en sus cursos. ¿Podrían proporcionarme el cupón de descuento, por favor?`;
        window.open(
          `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(message)}`,
          "_blank"
        );
      }
    });
  };

  return (
    <section className="invitacion-descuento">
      <div className="container py-3 px-3">
        <div className="row my-4">
          <h2 className="text-center text-white mb-4">
            ¿Conoces a alguien a quien podrían interesarle nuestros cursos?
          </h2>
        </div>
        <div className="row">
          {/* Columna Izquierda */}
          <div className="col-lg-8 col-12 mb-3 label-text">
            <p className="text-white fs-4">
              Envíanos su contacto y obtendrás un cupón de descuento exclusivo
              para brindarle una oportunidad de crecimiento.
            </p>{" "}
            <p className="text-white fs-4">
              {" "}
              <b> ¡Regálale un mejor futuro! </b>
            </p>
          </div>
          {/* Columna Derecha */}
          <div
            className="col-lg-4 col-12 d-flex align-items-center justify-content-center "
            data-aos="fade-left"
            data-aos-delay={200}
          >
            <button
              className="btn btn-white-red p-4 shadow fw-bold"
              onClick={handleWhatsApp}
            >
              Enviar contacto por WhatsApp
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default InvitacionDescuento;
