import React from "react";
import { FaCheckCircle } from "react-icons/fa";
import {
  Card,
  Table,
  ListGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import Swal from "sweetalert2";
import "../styles/Certificaciones.css";
import certificacionesData from "../data/certificacionesData";

const whatsappNumber = "5579118589";

const handleWhatsApp = (nombreCurso) => {
  Swal.fire({
    title: "Abriremos WhatsApp",
    text: `Te brindaremos más información sobre el ${nombreCurso} sin ningún compromiso. Nuestro objetivo en Fundación Nican es ayudarte a tomar la mejor decisión para tu desarrollo profesional.`,
    icon: "info",
    confirmButtonText: "Aceptar",
    confirmButtonColor: "var(--primary-color)",
  }).then((result) => {
    if (result.isConfirmed) {
      const message = `¡Hola! Estoy interesado/a en recibir información sobre el ${nombreCurso}. ¿Podrías ayudarme con los detalles?`;
      window.open(
        `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(message)}`,
        "_blank"
      );
    }
  });
};

function Certificaciones() {
  return (
    <div
      id="certificaciones"
      className="certificaciones-container"
      data-aos="fade-right"
    >
      <h1 className="text-center mb-4">Certificaciones en Fundación Nican</h1>
      <p className="text-center mb-5">
        Obtén conocimientos y habilidades prácticas con nuestros programas
        certificados en salud y bienestar.
      </p>

      {/* Tabla de Certificaciones */}
      <Table
        striped
        bordered
        hover
        responsive
        className="certificaciones-table"
      >
        <thead>
          <tr>
            <th
              style={{
                backgroundColor: "var(--primary-color)",
                color: "white",
                textAlign: "center",
              }}
            >
              Curso | Diplomado | Certificación
            </th>
            <th
              style={{
                backgroundColor: "var(--primary-color)",
                color: "white",
                textAlign: "center",
              }}
            >
              Temario
            </th>
            <th
              style={{
                backgroundColor: "var(--primary-color)",
                color: "white",
                textAlign: "center",
              }}
            >
              Beneficios y Oportunidades
            </th>
            {/*
               <th
              style={{
                backgroundColor: "var(--primary-color)",
                color: "white",
                textAlign: "center",
              }}
            >
              Duración
            </th>
          
               */}
          </tr>
        </thead>
        <tbody>
          {certificacionesData.map((item, index) => (
            <tr key={index}>
              <td className="pt-3" style={{ textAlign: "center" }}>
                <span className="my-4">
                  <b>{item.nombre}</b>{" "}
                </span>

                <button
                  className="btn btn-sm my-4"
                  style={{
                    backgroundColor: "var(--primary-color)",
                    color: "white",
                  }}
                  onClick={() => {
                    const link = document.createElement("a");
                    link.href = require(`../data/Informacion/${item.descargable}`);
                    link.download = item.descargable;
                    link.click();
                  }}
                >
                  más información
                </button>
              </td>

              <td style={{ backgroundColor: "white" }}>
                <ListGroup variant="flush">
                  {item.temario.map((tema, i) => (
                    <OverlayTrigger
                      key={i}
                      placement="top"
                      overlay={<Tooltip>{tema.descripcion}</Tooltip>}
                    >
                      <ListGroup.Item className="temario-item">
                        <FaCheckCircle className="temario-icon" /> {tema.tema}
                      </ListGroup.Item>
                    </OverlayTrigger>
                  ))}
                </ListGroup>
              </td>
              <td style={{ textAlign: "justify" }}>{item.beneficios}</td>
              {/*
                     <td style={{ textAlign: "justify" }}>
                {item.duracion || "Información sin brindar"}
              </td>
             
                 */}
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Línea de Tiempo 
      <div className="timeline-container mt-5">
        <h2 className="text-center">Pasos para Inscribirte</h2>
        <div className="timeline">
          <div className="timeline-step">
            <Card>
              <Card.Header>1. Selecciona el Curso</Card.Header>
              <Card.Body>
                Elige el curso que mejor se adapte a tus intereses y objetivos
                profesionales.
              </Card.Body>
            </Card>
          </div>
          <div className="timeline-step">
            <Card>
              <Card.Header>2. Confirma Horario</Card.Header>
              <Card.Body>
                Selecciona el horario que se ajuste a tu disponibilidad.
              </Card.Body>
            </Card>
          </div>
          <div className="timeline-step">
            <Card>
              <Card.Header>3. Revisa el Temario</Card.Header>
              <Card.Body>
                <ListGroup variant="flush">
                  {certificacionesData[0].temario.map((tema, i) => (
                    <ListGroup.Item key={i} className="temario-item">
                      <FaCheckCircle className="temario-icon" /> {tema.tema}
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </Card.Body>
            </Card>
          </div>
          <div className="timeline-step">
            <Card>
              <Card.Header>4. Conoce el Costo y Requisitos</Card.Header>
              <Card.Body>
                <p>
                  Costo:{" "}
                  {certificacionesData[0].costo || "Información sin brindar"}
                </p>
                <p>
                  Documentación: Identificación oficial, comprobante de
                  domicilio, certificado de estudios.
                </p>
                <button
                  className="btn"
                  style={{
                    backgroundColor: "var(--primary-color)",
                    color: "white",
                  }}
                >
                  Inscribirse
                </button>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>*/}
    </div>
  );
}

export default Certificaciones;
