import React, { useState, useEffect } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import fundacionNicanImg from "../imgs/Logo_Nican.png";
import "../styles/Header.css";

function Header() {
  const [activeTab, setActiveTab] = useState("home");
  const [expanded, setExpanded] = useState(false); // Estado para controlar el menú
  const navigate = useNavigate();
  const location = useLocation();

  const handleScroll = () => {
    const aboutSection = document.getElementById("about");
    const certificacionesSection = document.getElementById("certificaciones");
    const mapaSection = document.getElementById("mapa");
    const contactSection = document.getElementById("contact");
    const faqSection = document.getElementById("FQA");

    const scrollPosition = window.scrollY + 100;

    if (aboutSection && scrollPosition >= aboutSection.offsetTop) {
      setActiveTab("about");
    }
    if (
      certificacionesSection &&
      scrollPosition >= certificacionesSection.offsetTop
    ) {
      setActiveTab("certificaciones");
    }
    if (mapaSection && scrollPosition >= mapaSection.offsetTop) {
      setActiveTab("mapa");
    }
    if (contactSection && scrollPosition >= contactSection.offsetTop) {
      setActiveTab("contact");
    }
    if (faqSection && scrollPosition >= faqSection.offsetTop) {
      setActiveTab("FQA");
    }
  };

  const handleLinkClick = (tab, sectionId) => {
    setActiveTab(tab);
    setExpanded(false); // Cierra el menú después de hacer clic en un enlace

    if (location.pathname !== "/") {
      // Si no estamos en la página principal, redirigir a "/"
      navigate("/", { replace: true });
    }

    setTimeout(() => {
      // Desplazarse a la sección correspondiente después de navegar
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }, 100); // Pequeño retraso para asegurar que la navegación se complete
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <Navbar
      expand="lg"
      fixed="top"
      className="header-navbar"
      expanded={expanded}
    >
      <Navbar.Brand href="/" className="text-white pl-3">
        <img
          style={{
            width: 90,
            height: 80,
            textAlign: "center",
            padding: 0,
            margin: 0,
            marginLeft: 16,
          }}
          src={fundacionNicanImg}
        />
      </Navbar.Brand>
      <Navbar.Toggle
        aria-controls="basic-navbar-nav"
        onClick={() => setExpanded(expanded ? false : "expanded")}
        className="navbar-toggler-custom"
      />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          <Nav.Link
            className={`nav-link-custom ${
              activeTab === "about" ? "active" : ""
            }`}
            onClick={() => handleLinkClick("about", "about")}
          >
            Quienes Somos
          </Nav.Link>
          <Nav.Link
            className={`nav-link-custom ${
              activeTab === "certificaciones" ? "active" : ""
            }`}
            onClick={() =>
              handleLinkClick("certificaciones", "certificaciones")
            }
          >
            Certificaciones / Diplomados
          </Nav.Link>
          <Nav.Link
            className={`nav-link-custom ${
              activeTab === "mapa" ? "active" : ""
            }`}
            onClick={() => handleLinkClick("mapa", "mapa")}
          >
            Sede
          </Nav.Link>
          <Nav.Link
            className={`nav-link-custom ${
              activeTab === "calificaciones" ? "active" : ""
            }`}
            onClick={() => handleLinkClick("calificaciones", "calificaciones")}
          >
            Opiniones
          </Nav.Link>
          <Nav.Link
            className={`nav-link-custom ${
              activeTab === "contact" ? "active" : ""
            }`}
            onClick={() => handleLinkClick("contact", "contact")}
          >
            Contacto
          </Nav.Link>
          <Nav.Link
            className={`nav-link-custom ${activeTab === "FQA" ? "active" : ""}`}
            onClick={() => handleLinkClick("FQA", "FQA")}
          >
            Preguntas Frecuentes
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default Header;
