import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import Header from "./components/Header";
import HeroSection from "./components/HeroSection";
import AboutUs from "./components/AboutUs";
import Services from "./components/Services";
import ContactForm from "./components/ContactForm";
import Footer from "./components/Footer";
import AQuienesBuscamos from "./components/AQuienesBuscamos";
import Certificaciones from "./components/Certificaciones";
import Mapa from "./components/Mapa";
import PreguntasFrecuentes from "./components/PreguntasFrecuentes";
import InvitacionDescuento from "./components/InvitacionDescuento";
import FloatingWhatsApp from "./components/FloatingWhatsApp";
import InscripcionPasos from "./components/InscripcionPasos";
import AvisoDePrivacidad from "./components/AvisoDePrivacidad";
import Conocer from "./components/Conocer";
import CertificadosCarousel from "./components/CertificadosCarousel";
import CalificacionesRocola from "./components/Calificaciones";
import VideoRecomendacion from "./components/VideoRecomendacion";

function App() {
  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);

  return (
    <Router>
      <Header />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <HeroSection />
              <AboutUs />
              <Services />
              <InvitacionDescuento />
              <CertificadosCarousel />
              <Certificaciones />
              <AQuienesBuscamos className="my-5" />
              <Mapa className="my-5" />
              <InscripcionPasos />
              <CalificacionesRocola className="my-5" />
              <VideoRecomendacion />
              <ContactForm className="my-5" />
              <PreguntasFrecuentes className="my-5" />
            </>
          }
        />
        <Route path="/AvisoDePrivacidad" element={<AvisoDePrivacidad />} />
        <Route path="/Conocer" element={<Conocer />} />
        {/* Ruta comodín para redirigir a la página principal */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <Footer />
      <FloatingWhatsApp />
    </Router>
  );
}

export default App;
