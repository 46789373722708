import React, { useState, useEffect } from "react";
import "../styles/HeroSection.css";

function HeroSection() {
  const [images, setImages] = useState([]);

  // Cargar imágenes de la carpeta /img/hero-section de forma dinámica
  useEffect(() => {
    const importAll = (r) => r.keys().map(r);
    const images = importAll(
      require.context("../imgs/hero-section", false, /\.(png|jpe?g|svg)$/)
    );
    setImages(images);
  }, []);

  return (
    <div className="hero-section mt-5" id="home">
      <div className="hero-row text-center my-1 my-md-3">
        <h1 className="c-white">Fundación Nican A.C.</h1>
        <p className="my-2 my-md-0">
          Transformando a especialistas en agentes de cambio.
        </p>
      </div>

      <div
        id="heroCarousel"
        className="carousel slide hero-carousel"
        data-bs-ride="carousel"
      >
        <div className="carousel-inner">
          {images.map((image, index) => (
            <div
              key={index}
              className={`carousel-item ${index === 0 ? "active" : ""}`}
            >
              <img
                src={image}
                alt={`Certificaciones Fundación Nican | Ixtapaluca Edo. Mex. | Slide ${
                  index + 1
                }`}
                className="carousel-image"
              />
            </div>
          ))}
        </div>
        <button
          className="carousel-control-prev fixed-controls"
          type="button"
          data-bs-target="#heroCarousel"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next fixed-controls"
          type="button"
          data-bs-target="#heroCarousel"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
}

export default HeroSection;
