import React from "react";
import "../styles/Mapa.css";
import {
  FaFacebook,
  FaWhatsapp,
  FaEnvelope,
  FaGlobe,
  FaShareAlt,
} from "react-icons/fa";

const websiteURL = "https://nican-landing-page.vercel.app/";

function Mapa() {
  const handleShare = () => {
    const message = `Centro Capacitador y Evaluador Nican\nHorario de oficina: 9:00 am - 3:00 pm de Lunes a Sábado.\n\nContáctanos:\n📞 WhatsApp: 5579118589\n✉️ Correo: fundacionnican2020@hotmail.com\n🌐 Facebook: https://www.facebook.com/centro.Nican\n📍 Google Maps: https://www.google.com.mx/maps/search/+linderos+de+ixtapaluca+pozo+9,+Ixtapaluca,+Mexico,+56530`;
    window.open(`https://wa.me/?text=${encodeURIComponent(message)}`, "_blank");
  };

  return (
    <div className="mapa-container container mb-5" id="mapa" data-aos="fade-up">
      <div className="row my-5">
        <h2 className="text-center">Nos encontramos cerca de ti</h2>
      </div>
      <div className="row mt-4">
        {/* Columna Izquierda - Información de contacto */}
        <div className="col-md-6 contact-info">
          <h3>Centro Capacitador y Evaluador Nican</h3>
          <p>
            <strong>Horario de oficina:</strong> 9:00 am - 3:00 pm de Lunes a
            Sábado
          </p>
          <div className="contact-links">
            <div className="contact-item">
              <FaWhatsapp className="icon" />
              <a
                href="https://wa.me/525579118589?text=Podría%20solicitar%20un%20poco%20más%20de%20información%20de%20sus%20cursos?"
                target="_blank"
                rel="noopener noreferrer"
              >
                5579118589
              </a>
            </div>
            <div className="contact-item">
              <FaEnvelope className="icon" />
              <a
                href="mailto:fundacionnican2020@hotmail.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                fundacionnican2020@hotmail.com
              </a>
            </div>
            <div className="contact-item">
              <FaFacebook className="icon" />
              <a
                href="https://www.facebook.com/centro.Nican?mibextid=ZbWKwL"
                target="_blank"
                rel="noopener noreferrer"
              >
                Centro Nican en Facebook
              </a>
            </div>
            <div className="contact-item">
              <FaGlobe className="icon" />
              <a href={websiteURL} target="_blank" rel="noopener noreferrer">
                Visita nuestra página Web
              </a>
            </div>
          </div>
          <div className="text-center mt-5">
            <button className="btn btn-share mb-5" onClick={handleShare}>
              <FaShareAlt /> Compartir
            </button>
          </div>
        </div>

        {/* Columna Derecha - Mapa */}
        <div className="col-md-6">
          <iframe
            title="Fundación Nican Ubicación"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3761.2180142510426!2d-98.8636925!3d19.3384195!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85cd888c50a1532d%3A0x4ff7b6fcaeeb2c4!2sLinderos%20de%20Ixtapaluca%20Pozo%209%2C%20Ixtapaluca%2C%20Mexico%2C%2056530!5e0!3m2!1ses!2smx!4v1638299084296!5m2!1ses!2smx"
            width="100%"
            height="400"
            style={{ border: 0, borderRadius: "15px" }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default Mapa;
